var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.shipment)?_c('section',{staticClass:"shipment-table"},[_c('TableInfo'),_c('table',[_c('TableHeader'),_vm._l((_vm.shipment),function(shop,i){return _c('tr',{key:i},[_c('td',{style:({
          background: shop.name.includes('РЕКЛАМАЦИИ') ? shop.shopBG : '#fff',
        })},[_vm._v(" "+_vm._s(i + 1)+" ")]),_c('td',{staticClass:"car",style:({
          background: shop.name.includes('РЕКЛАМАЦИИ')
            ? shop.shopBG
            : shop.carBG,
          color: shop.textColor,
          'font-weight': 800,
        }),on:{"click":_vm.changeColor}},[_vm._v(" "+_vm._s(shop.car ? shop.car : "Не указана")+" ")]),_c('td',{style:({ fontWeight: 'bold', background: shop.shopBG })},[_vm._v(" "+_vm._s(shop.name)+" ")]),_c('td',{style:({
          background: shop.name.includes('РЕКЛАМАЦИИ') ? shop.shopBG : '#fff',
        })},[_c('span',{staticClass:"yellow tablepart"},[_vm._v(" "+_vm._s(shop.thinCatalog ? shop.thinCatalog.quan + "\n" + shop.thinCatalog.region : null))])]),_c('td',{style:({
          background: shop.name.includes('РЕКЛАМАЦИИ') ? shop.shopBG : '#fff',
        })},[_c('span',{staticClass:"yellow tablepart"},[_vm._v(_vm._s(shop.notebook))]),_c('br'),(shop.notebook)?_c('span',{staticClass:"yellow tablepart",staticStyle:{"font-size":"12px"}},[_vm._v("блокноты")]):_vm._e()]),_c('td',{style:({
          background: shop.name.includes('РЕКЛАМАЦИИ') ? shop.shopBG : '#fff',
        })},[_c('span',{staticClass:"yellow tablepart"},[_vm._v(_vm._s(shop.cup))]),_c('br'),(shop.cup)?_c('span',{staticClass:"yellow tablepart",staticStyle:{"font-size":"12px"}},[_vm._v("кружки")]):_vm._e()]),_c('td',{style:({
          background: shop.name.includes('РЕКЛАМАЦИИ') ? shop.shopBG : '#fff',
        })},[_c('span',{staticClass:"yellow tablepart"},[_vm._v(_vm._s(shop.pack))]),_c('br'),(shop.pack)?_c('span',{staticClass:"yellow tablepart",staticStyle:{"font-size":"12px"}},[_vm._v("упак.")]):_vm._e()]),_c('td',{style:({
          background: shop.name.includes('РЕКЛАМАЦИИ') ? shop.shopBG : '#fff',
        })},[_c('span',{staticClass:"yellow tablepart"},[_vm._v(_vm._s(shop.folder))]),_c('br'),(shop.folder)?_c('span',{staticClass:"yellow tablepart",staticStyle:{"font-size":"12px"}},[_vm._v("папки")]):_vm._e()]),(_vm.towels)?_c('td',{style:({
          background: shop.name.includes('РЕКЛАМАЦИИ') ? shop.shopBG : '#fff',
        })},[_c('span',{staticClass:"yellow tablepart"},[_vm._v(_vm._s(shop.towel))]),_c('br'),(shop.towel)?_c('span',{staticClass:"yellow tablepart",staticStyle:{"font-size":"12px"}},[_vm._v("полотенца")]):_vm._e()]):_vm._e(),_c('td',{style:({
          background: shop.name.includes('РЕКЛАМАЦИИ') ? shop.shopBG : '#fff',
        })},[_c('span',{staticClass:"yellow tablepart"},[_vm._v(_vm._s(shop.vine))]),_c('br'),(shop.vine)?_c('span',{staticClass:"yellow tablepart",staticStyle:{"font-size":"12px"}},[_vm._v("шампанское")]):_vm._e()]),_c('td',{staticClass:"other-mats",style:({
          background: shop.name.includes('РЕКЛАМАЦИИ') ? shop.shopBG : '#fff',
        })},[_vm._l((shop.otherMats),function(mat,i){return _c('p',{key:i,class:{ yellow: !_vm.roomMaterials.includes(mat.name) },style:({
            color: mat.quan ? "#000" : "red",
            fontWeight: mat.quan ? "normal" : "bold",
            background: _vm.markers.map(function (m) { return m.material; }).includes(mat.name)
              ? _vm.markers.filter(function (m) { return m.material === mat.name; })[0].color
              : null,
          })},[_vm._v(" "+_vm._s(mat.quan ? ((mat.name) + " - " + (mat.quan)) : ("" + (mat.name)))+" ")])}),_vm._l((shop.samples),function(sample,i){return _c('p',{key:'sample-' + i,class:{ yellow: !_vm.roomMaterials.includes(sample.name) }},[_vm._v(" "+_vm._s(((sample.name) + " - " + (sample.quan)))+" ")])})],2),_c('td',{staticClass:"boxes",style:({
          background: shop.name.includes('РЕКЛАМАЦИИ') ? shop.shopBG : '#fff',
        }),on:{"click":function($event){$event.preventDefault();return _vm.changeBoxes.apply(null, arguments)}}},[_c('input',{staticClass:"boxes__input",attrs:{"type":"number","name":"boxesNumber","id":("boxes-" + (i + 1))}}),_c('p',{staticClass:"user-boxes",staticStyle:{"font-size":"16px","font-weight":"bold"},on:{"click":function($event){$event.preventDefault();return _vm.redirectClick.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.stickers[_vm.activeRegion][shop.name] ? _vm.stickers[_vm.activeRegion][shop.name].stickers : "")+" ")])]),_c('td',{style:({
          background: shop.name.includes('РЕКЛАМАЦИИ') ? shop.shopBG : '#fff',
        })},_vm._l((shop.orders),function(order){return _c('div',{key:order.name},[(order.comment && order.picked)?_c('p',{style:({ background: "#53DF00" })},[_vm._v(" "+_vm._s(("Р-" + (order.name.match(/\d+/)[0])))+" ")]):_c('p',[_vm._v(" "+_vm._s(("Р-" + (order.name.match(/\d+/)[0])))+" ")])])}),0)])})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }